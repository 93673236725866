@import "../../foundation/setting";

.p-common-headline {
	margin-bottom: 3.5rem;
	color: inherit;
	font-weight: bold;
	font-size: 3.6rem;
	position: relative;
	@include line-height(36, 36);
	@include mq('max', 'xxl') {
		font-size: 4.2rem;
		@include line-height(42, 42);
	}
	@include mq('max') {
		margin-bottom: 1.4rem;
		@include line-height(34, 20);
	}
	@include mq('max', 'md') {
		font-size: 2.4rem;
	}
	.word {
		position: relative;
		display: inline-block;
		overflow: hidden;
		vertical-align: bottom;
		-webkit-transform: translate(0, 20%);
		transform: translate(0, 20%);
		transition: 1s cubic-bezier(0.36, 0.14, 0, 1);
		.o {
			display: inline-block;
			vertical-align: bottom;
			-webkit-transform: translate(0, 100%);
			transform: translate(0, 100%);
			transition: 1s cubic-bezier(0.36, 0.14, 0, 1);
		}
	}
	&.is-active {
		.word {
			transform: translate(0, 0);
			.o {
				transform: translate(0, 0);
			}
		}
	}
}


/*current-nav
---------------------------------------------------------- */
.p-side-nav {
	position: absolute;
	top: 0;
	right: 0;
	width: 100%;
	height: 100%;
	@include mq('max') {
		display: none;
	}
}

.p-side-nav__list {
	position: absolute;
	top: 0;
	right: 0;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;
}

.p-side-nav__item {
	&:not(:first-child) {
		margin-top: 2rem;
	}
	&[data-link-current="true"] {
		.p-side-nav__list-link {
			&:after {
				transform: scaleX(1);
			}
		}
	}
}

.p-side-nav__list-link {
	position: relative;
	display: block;
	display: block;
	width: 1.6rem;
	height: 1.6rem;
	margin: auto;
	border: solid 2px $color-main;
	border-radius: 50%;
	&:after {
		position: absolute;
		top: 0;
		right: -1rem;
		bottom: 0;
		width: 1.6rem;
		height: 2px;
		margin: auto;
		background-color: $color-main;
		content: '';
		transform: scaleX(0);
		transform-origin: 0 0;
		transition: transform 0.4s cubic-bezier(.39, .575, .565, 1);
	}
}

/*hero
---------------------------------------------------------- */
.p-mv {
	position: relative;
    margin-bottom: 14rem;
	@include mq('max','md') {
		margin-bottom: 17vw;
	}
}

.p-mv__inner {
	position: relative;
}

/* .tablet.safari .p-mv,
.mobile.safari .p-mv {
	height: calc(var(--vh, 1vh) * 100);
} */

.p-mv__inner {
	position: relative;
}

.p-mv__copy {
	width: 39%;
	height: 55rem;
	position: relative;
	img {
		position: absolute;
		top: 50%;
		left: 4rem;
		transform: translateY(-50%);
		width: 47.4rem;
		z-index: 1;
	}
	@include mq('max','md') {
		width: 90%;
    	height: 115vw;
		img {
			top: 2rem;
			left: 2rem;
			transform: unset;
			width: 58vw;
		}
	}
}

.p-mv__img {
	position: absolute;
	right: 0;
	top: 4rem;
	height: 65rem;
	width: 89%;
	background-image: url(/assets/img/top/mv_img.jpg);
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
	@include mq('max','md') {
		top: 19vw;
		height: 113vw;
		width: 87%;
		background-position: 30%;
	}
}

/*p-note
---------------------------------------------------------- */
.p-note {
	margin-bottom: 10rem;
	width: 100%;
	background-color: $color-graytone02;
}

.p-note__inner {
	display: flex;
	width: 100%;
	max-width: calc(1120px + 32rem);
	margin: auto;
	padding-right: 16rem;
	padding-left: 16rem;
	padding-top: 3rem;
	padding-bottom: 3rem;
	.p-note__title {
		color: $color-main;
		margin-right: 7rem;
	}
	time {
		margin-right: 2.4rem;
	}
	@include mq('max') {
		padding-right: 0;
		padding-left: 0;
	}
	@include mq('max', 'md') {
		flex-direction: column;
	}
}

/*p-news
---------------------------------------------------------- */
.p-news {
	width: 100%;
	max-width: calc(1120px + 32rem);
	margin: auto;
	padding-right: 16rem;
	padding-left: 16rem;
	margin-bottom: 10rem;
	@include mq('max') {
		padding: 0;
		margin-bottom: 5rem;
	}
}

.p-news__list {
	margin-bottom: 4rem;
}

.p-news__list-item {
	padding: 3rem 0;
	border-bottom: 1px solid $color-graytone01;
	a {
		display: block;
		position: relative;
		&::after {
			position: absolute;
			right: 1rem;
			top: 50%;
			transform: translateY(-50%);
			font-weight: normal;
			font-family: "icomoon";
			font-style: normal;
			font-variant: normal;
			font-variant: normal;
			line-height: 1;
			letter-spacing: 0;
			text-transform: none;
			speak: none;
			-ms-font-feature-settings: "liga" 1;
			font-feature-settings: "liga";
			font-variant-ligatures: discretionary-ligatures;
			-webkit-font-smoothing: antialiased;
			-moz-osx-font-smoothing: grayscale;
			content: icon($icon-angle-right);
			color: $color-main;
			font-size: 1.7rem;
		}
		&[href$=".pdf"] {
			&::after {
				content: icon($icon-pdf);
				font-size: 2.6rem;
			}
		}
		.p-news__list-item-inner {
			padding-right: 5rem;
		}
	}
}



.p-news__list-head {
	display: flex;
    align-items: center;
	margin-bottom: 1rem;
	time {
		font-size: 1.6rem;
		color: $color-maintone02;
		margin-right: 3rem;
	}
	@include mq('max') {
		time {
			font-size: 1.4rem;
		}
	}
}

.p-news__label {
	display: inline-block;
	font-size: 1.2rem;
	border-radius: 50px;
	&:not(:last-child) {
		margin-right: 1rem;
	}
	&.-info {
		color: $color-white;
		border: 1px solid $color-main;
		background-color: $color-main;
		padding: 0.5rem 3rem;
	}
	&.-new {
		font-family: $font-en;
		color: $color-must;
		border: 1px solid $color-must;
		padding: 0.5rem 2rem;
	}
	@include mq('max') {
		font-size: 1rem;
		&.-info {
			padding: 0.2rem 1.5rem;
		}
		&.-new {
			padding: 0.2rem 1rem;
		}
	}
}

.c-paging-number{
	position: relative;
	display: flex;
	align-items: center;
.c-paging-number-button-prev{
	position: absolute;
	::before {
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%,-50%);
		font-weight: normal;
		font-family: "icomoon";
		font-style: normal;
		font-variant: normal;
		font-variant: normal;
		line-height: 1;
		letter-spacing: 0;
		text-transform: none;
		// speak: none;
		-ms-font-feature-settings: "liga" 1;
		font-feature-settings: "liga";
		font-variant-ligatures: discretionary-ligatures;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		content: icon($icon-angle-left);
		color: $color-main;
		font-size: 1.7rem;
	}
	
}
.c-paging-number-button-next{
	position: absolute;
	::before {
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%,-50%);
		font-weight: normal;
		font-family: "icomoon";
		font-style: normal;
		font-variant: normal;
		font-variant: normal;
		line-height: 1;
		letter-spacing: 0;
		text-transform: none;
		// speak: none;
		-ms-font-feature-settings: "liga" 1;
		font-feature-settings: "liga";
		font-variant-ligatures: discretionary-ligatures;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		content: icon($icon-angle-left);
		color: $color-main;
		font-size: 1.7rem;
	}
	
}
// .c-paging-number-button-next{
// 	position: absolute;
// 	display: contents;
// 	justify-content: flex-end;
// 	::after {
// 		position: absolute;
// 		right: 1rem;
// 		top: 50%;
// 		transform: translateY(-50%);
// 		font-weight: normal;
// 		font-family: "icomoon";
// 		font-style: normal;
// 		font-variant: normal;
// 		font-variant: normal;
// 		line-height: 1;
// 		letter-spacing: 0;
// 		text-transform: none;
// 		speak: none;
// 		-ms-font-feature-settings: "liga" 1;
// 		font-feature-settings: "liga";
// 		font-variant-ligatures: discretionary-ligatures;
// 		-webkit-font-smoothing: antialiased;
// 		-moz-osx-font-smoothing: grayscale;
// 		content: icon($icon-angle-right);
// 		color: $color-main;
// 		font-size: 1.7rem;
// 	}
// }
}



/*p-business
---------------------------------------------------------- */
.p-business__content {
	background-image: url(/assets/img/top/bg_business.jpg);
	background-size: cover;
	background-repeat: no-repeat;
	@include mq('max') {
		background-image: url(/assets/img/top/bg_business_sp.jpg);
	}
}

.p-business__body {
	width: 100%;
	max-width: calc(1120px + 32rem);
	margin: auto;
	padding-right: 16rem;
	padding-left: 16rem;
	padding-top: 7rem;
	padding-bottom: 7rem;
	display: grid;
	grid-template-columns: repeat(10, 1fr);
	height: 100%;
	@include mq('max') {
		padding-right: 2rem;
		padding-left: 2rem;
		padding-top: 11rem;
		padding-bottom: 3rem;
	}
}

.p-business__box {
	background-color: $color-white;
	grid-column: 7/11;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	padding: 6rem;
	@include mq('max') {
		grid-column: 1/11;
		padding: 4rem 3rem;
	}
}

/*p-company
---------------------------------------------------------- */
.p-company__content {
	background-image: url(/assets/img/top/bg_company.jpg);
	background-size: cover;
	background-repeat: no-repeat;
	padding: 4rem 0;
	a {
		display: flex;
		justify-content: right;
	}
	@include mq('max') {
		a {
			justify-content: center;
		}
	}
}

.p-company__content-inner {
	width: 68rem;
	margin-right: 0;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding-right: 4rem;
	@include mq('max') {
		padding-right: 2rem;
		padding-left: 2rem;
	}
}

.p-company__head {
	.c-headline-lv1 {
		position: relative;
		&::after {
			position: absolute;
			top: 50%;
			right: -8rem;
			transform: translateY(-50%);
			content: icon($icon-angle-right);
			font-size: 1.7rem;
			color: $color-main;
			font-weight: normal;
			font-family: "icomoon";
			font-style: normal;
			font-variant: normal;
			font-variant: normal;
			line-height: 1;
			letter-spacing: 0;
			text-transform: none;
			speak: none;
			-ms-font-feature-settings: "liga" 1;
			font-feature-settings: "liga";
			font-variant-ligatures: discretionary-ligatures;
			-webkit-font-smoothing: antialiased;
			-moz-osx-font-smoothing: grayscale;
			width: 6rem;
			height: 6rem;
			border: 1px solid $color-main;
			border-radius: 50%;
			background-color: $color-white;
			display: flex;
			justify-content: center;
			align-items: center;
		}
		@include mq('max') {
			&::after {
				right: -6rem;
				width: 4rem;
				height: 4rem;
			}
		}
	}
}

.p-company__body {
	img {
		width: 34.4rem;
	}
	@include mq('max') {
		text-align: right;
		width: 53%;
		img {
			width: 100%;
			max-width: 34.4rem;
		}
	}
}

/*p-recruit
---------------------------------------------------------- */
.p-recruit__content {
	background-image: url(/assets/img/top/bg_recruit.jpg);
	background-size: cover;
	background-repeat: no-repeat;
	padding: 4rem 0;
	a {
		display: flex;
		justify-content: left;
	}
	@include mq('max') {
		a {
			justify-content: center;
		}
	}
}

.p-recruit__content-inner {
	width: 68rem;
	margin-right: 0;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding-left: 4rem;
	@include mq('max') {
		padding-right: 2rem;
		padding-left: 2rem;
	}
}

.p-recruit__head {
	.c-headline-lv1 {
		position: relative;
		&::after {
			position: absolute;
			top: 50%;
			right: -8rem;
			transform: translateY(-50%);
			content: icon($icon-angle-right);
			font-size: 1.7rem;
			color: $color-main;
			font-weight: normal;
			font-family: "icomoon";
			font-style: normal;
			font-variant: normal;
			font-variant: normal;
			line-height: 1;
			letter-spacing: 0;
			text-transform: none;
			speak: none;
			-ms-font-feature-settings: "liga" 1;
			font-feature-settings: "liga";
			font-variant-ligatures: discretionary-ligatures;
			-webkit-font-smoothing: antialiased;
			-moz-osx-font-smoothing: grayscale;
			width: 6rem;
			height: 6rem;
			border: 1px solid $color-main;
			border-radius: 50%;
			background-color: $color-white;
			display: flex;
			justify-content: center;
			align-items: center;
		}
		@include mq('max') {
			&::after {
				right: -6rem;
				width: 4rem;
				height: 4rem;
			}
		}
	}
}

.p-recruit__body {
	img {
		width: 34.4rem;
	}
	@include mq('max') {
		text-align: right;
		width: 53%;
		img {
			width: 100%;
			max-width: 34.4rem;
		}
	}
}

/*p-group-companies
---------------------------------------------------------- */
.p-group-companies {
	width: 100%;
	max-width: calc(1120px + 32rem);
	margin: auto;
	padding-right: 16rem;
	padding-left: 16rem;
	@include mq('max') {
		padding-right: 0;
		padding-left: 0;
	}
}

.p-group-companies__head {
	text-align: center;
	margin-bottom: 6rem;
	.c-title-lv1__text {
		justify-content: center;
	}
	@include mq('max') {
		margin-bottom: 3rem;
	}
}

.p-group-companies__link-list {
	display: flex;
	justify-content: center;
	@include mq('max') {
		flex-direction: column;
		align-items: center;
	}
}

.p-group-companies__link-item {
	margin-right: 5rem;
	display: block;
	.c-icon-blank {
		color: $color-main;
	}
	@include mq('max') {
		margin-right: 0;
		margin-bottom: 1rem;
	}
}

.p-group-companies__link-text {
	margin-right: 1.5rem;
	font-size: 2.2rem;
	@include mq('max') {
		font-size: 1.8rem;
		margin-right: 1rem;
	}
}